import React from 'react'
import {BsWhatsapp} from 'react-icons/bs'
import{BsFacebook} from 'react-icons/bs'



const HeaderScocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://wa.me/message/JI2QY544E4AGB1' target="_blank"><BsWhatsapp/></a>
        <a href='https://www.facebook.com/eddiebluesdigital-105608855623755/' target="_blank"><BsFacebook/></a>
        
    </div>
  )
}

export default HeaderScocials