import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";



// import required modules
import { Pagination } from "swiper";

const data = [

   {
     avatar:AVTR1,
     name: 'Blues Wayne',
     review:'I have a billionaires mindset, all the money I spend comes back to me multiplied, i am programmed for success'
   },
   {
    avatar:AVTR2,
    name: 'Blues Wayne',
    review:'I have a billionaires mindset, all the money I spend comes back to me multiplied, i am programmed for success'
   },
   {
    avatar:AVTR3,
    name: 'Blues Wayne',
    review:'I have a billionaires mindset, all the money I spend comes back to me multiplied, i am programmed for success'
   },
   {
   avatar:AVTR4,
   name: 'Blues Wayne',
   review:'I have a billionaires mindset, all the money I spend comes back to me multiplied, i am programmed for success'
   },

]

const Testimonials = () => {
  return (
    <section id='testimonials'>
    <h5>Review From Clients</h5>
    <h2>Testimonials</h2>
    <Swiper pagination={true} modules={[Pagination]} className='container testimonials__container'>
     {
      data.map(({avatar,name,review}) =>{
       return(
        <SwiperSlide className='testimonial'>
        <div className='client__avatar'>
         <img src={avatar} alt ="" />
         </div>
         <h5 className='client_name'>{name}lll</h5>
         <small className='client_review'>{review}</small>
        
     </SwiperSlide>
       )

      })
     }
    </Swiper>

    </section>
  )
}

export default Testimonials