import React from 'react'
import './header.css'
import CTA from './CTA'
import We from '../../assets/landing1.jpg'
import HeaderScocials from './HeaderScocials'

const Header = () => {
  return (
<header>
  <div className="container header__container">
    <h5>Hello We're</h5>
    <h1>Eddieblues Digital</h1>
    <h5>We Provide Tech Solutions For Tech Businesses Worldwide</h5>
    <h5 className='text-light'>Let's explore more ways to boost your business with modern technologies</h5>
    <CTA />
    <HeaderScocials/>
    <div className='we'>
     <img src={We} alt="" />
    </div>
    <a href='#contact' className='scroll__down'>Scroll Down</a>
  </div>
</header>
    
  )
}

export default Header