import React from 'react'
import './about.css'
import US from '../../assets/landing2.jpg'
import{BsFillAwardFill} from 'react-icons/bs'
import{FiUsers} from 'react-icons/fi'
import{RiFolderUploadFill} from 'react-icons/ri'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Us</h2>
      <div className="container about__container">
          <div className='about__us'>
          <div className='about__us-image'>
            <img src={US} alt="about us"/>
          </div>
          </div>
          
          <div className='about__content'>
          <div className='about__cards'>
       <article className='about__card'>
       <BsFillAwardFill className='about__icon'/>
        <h5>Experience</h5>
        <small>3+ years working Experience</small>
       </article>
         

       <article className='about__card'>
       <FiUsers className='about__icon'/>
       <h5>Clients</h5>
        <small>30+ Worldwide</small>
       </article>
        

          
       <article className='about__card'>
       <RiFolderUploadFill className='about__icon'/>
        <h5>Projects</h5>
        <small>80+ Completed</small>
       </article>
          </div>
          <p>
           As technology advances Client's expectations grow. With eddiebluesdigital tech guidance, you can be sure of providing the best experience for your 
           clients wherever they are.
          </p>
          <a href='#contact' className='btn btn-primary'>let's talk</a>
          </div>

      </div>
    </section>
  )
}

export default About