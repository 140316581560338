import React from 'react'
import './nav.css'
import{AiOutlineHome} from 'react-icons/ai'
import{FaUsersCog} from 'react-icons/fa'
import{BiNetworkChart} from 'react-icons/bi'
import{FaServicestack} from 'react-icons/fa'
import{BiMessageEdit} from 'react-icons/bi'
import { useState } from 'react'
const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
       <a href='#' onClick={() => setActiveNav('#')} className={activeNav === '#'? 'active':''}><AiOutlineHome/></a>
       <a href='#about' onClick={() => setActiveNav('#about')} className={activeNav === '#about'? 'active':''}><FaUsersCog/></a>
       <a href='#experience' onClick={() => setActiveNav('#experience')} className={activeNav === '#experience'? 'active':''}><BiNetworkChart/></a>
       <a href='#contact' onClick={() => setActiveNav('#contact')} className={activeNav === '#contact'? 'active':''}><BiMessageEdit/></a>
    </nav>
  )
}

export default Nav