import React from 'react'
import './contact.css'
import {FiMail} from 'react-icons/fi'
import {BsFacebook} from 'react-icons/bs'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ny2ajv5', 'template_401qqc4', form.current, 'VA45tZSVEsRLMuWkP')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <section id='contact'>
      <h5>Get In Touch With Us</h5>
      <div className='container contact__container'>
         <div className='contact__options'>
         <a href='mailto:support@eddiebluesdigital.com'>
         <article className='contact__option'>
          <FiMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>support@eddiebluesdigital.com</h5>
            <h3>Send Us a Message</h3>
          </article>
         
         
         </a>
         <a href='mailto:support@eddiebluesdigital.com'>
         <article className='contact__option'>
         <BsFacebook className='contact__option-icon'/>
            <h4>Facebook</h4>
            <h5>support@eddiebluesdigital.com</h5>
            <h3>Send Us a Message</h3>
          </article>
         
         
         </a>
         <a href='mailto:support@eddiebluesdigital.com'>
         <article className='contact__option'>
         <BsWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>support@eddiebluesdigital.com</h5>
            <h3>Send Us a Message</h3>
          </article>
         
         
         </a>
          
         </div>
         {/* End Of Contact Option */}
      
         <form ref={form} onSubmit={sendEmail}>
           <input type="text" name="name" placeholder = 'Your Full Name' required/>
           <input type="email" name="email" placeholder = 'Your Email' required/>
           <textarea name='message'  rows='7' placeholder='Your message' required></textarea>
           <button type='submit' className='btn btn-primary'>Send Message</button>
         </form>
      </div>
    </section>
  )
}

export default Contact