import React from 'react'
import './footer.css'
import {BsWhatsapp} from 'react-icons/bs'
import{BsFacebook} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer_logo'>eddiebluesdigital</a>
      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>
      <div className='footer__socials'>
      <a href='https://wa.me/message/JI2QY544E4AGB1' target="_blank"><BsWhatsapp/></a>
        <a href='https://www.facebook.com/eddiebluesdigital-105608855623755/' target="_blank"><BsFacebook/></a>
        
    </div>
    <div className='footer__copyright'>
        <small>&copy; eddiebluesdigital. All rights reserved</small>
    </div>
    </footer>
  )
}

export default Footer