import React from 'react'
import './experience.css'
import IMG1 from '../../assets/software.jpg'
import IMG2 from '../../assets/training.jpg'
import IMG3 from '../../assets/digital.jpg'
import IMG4 from '../../assets/graphic.jpg'
import IMG5 from '../../assets/consult.jpg'

const Experience = () => {
  return (
    <section id='experience'>
    <h5>What We Do</h5>
    <h2 className='text-light'>Here is how we can help your business grow</h2>
    <div className='container experience__container'>
      <div className='experience_frontend'>
      <h3>Software Development </h3>
        <div className='experience_content'>
         <article className='experience_details'>
          <div>          
            <h4>Responsive Websites, Web Applications, Desktop Applications, Android applications and IOS Applications</h4>
            <div className='experience_item-image'>
           <img src={IMG1} alt=""/>
           </div> 
          
           <a href='www.github.com' className='btn' target='_blank'>Start Developing</a>
          </div>
         </article>
       
        </div>
      </div>
      {/* End of front End */}
      <div className='experience_backend'>
      <h3>Training/Talent Development</h3>
        <div className='experience_content'>
        <article className='experience_details'>
          <div>         
            <h4>
            We Offer private and corporate training programs both online and offline on computer
            programming, web programming, web design, mobile app development, graphic designing,
             photoshop and many more.
            </h4>
            <div className='experience_item-image'>
           <img src={IMG2} alt=""/>
           </div> 
           <a href='www.github.com' className='btn btn-primary' target='_blank'>Plan a program</a>
           <a href='www.github.com' className='btn' target='_blank'>Apply to eddiebluesdigital campus</a>
          </div>
         </article>
        </div>

      </div>

            {/* End of front End */}
      <div className='experience_backend'>
      <h3>Branding & Digital Marketing</h3>
        <div className='experience_content'>
        <article className='experience_details'>
          <div>         
            <h4>
                eddiebluesdigital is a trusted choice for social media Marketing Services.
                 As your partner, we'll help your business improve its brand awareness,
                 customer loyalty, revenue and more.
            </h4>
            <div className='experience_item-image'>
           <img src={IMG3} alt=""/>
           </div> 
           <a href='www.github.com' className='btn' target='_blank'>Start a campaign</a>
          </div>
         </article>
        </div>
        </div>

        
            {/* End of front End */}
      <div className='experience_backend'>
      <h3>Graphic Design</h3>
        <div className='experience_content'>
        <article className='experience_details'>
          <div>         
            <h4>
           Our designs allow you to freely project your ideas and visually communicate with
           your target audience by combining Art and Tech through images, text and color
           , to achieve artistic or decorative effects and transform data into visual graphics.
            </h4>
            <div className='experience_item-image'>
           <img src={IMG4} alt=""/>
           </div> 
           <a href='www.github.com' className='btn' target='_blank'>Start a campaign</a>
          </div>
         </article>
        </div>
        </div>

        
            {/* End of front End */}
      <div className='experience_backend'>
      <h3>24/7 Free Tech Support & consultation</h3>
        <div className='experience_content'>
        <article className='experience_details'>
          <div>         
            <h4>
           We're always here, always ready to help, online or offline, our social media
           and contact managers are always active 24/7 to respond to you.
            </h4>
            <div className='experience_item-image'>
           <img src={IMG5} alt=""/>
           </div> 
           <a href='www.github.com' className='btn' target='_blank'>Contact Tech Support</a>
          </div>
         </article>
        </div>
        </div>
    </div>
    </section>
  )
}

export default Experience